@keyframes fillFromLeft {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.fill-left {
  animation: fillFromLeft 0.2s linear forwards;
}
